@import '../definitions.pcss';

.news
{
    .article
    {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .news-list-view
    {
        .news-item
        {
            &:nth-child(2n + 1)
            {
                clear: both;
            }

            .news-img-wrap
            {
                a
                {
                    border: none;
                    padding: 0;

                    img
                    {
                        border: 3px solid var(--background-color);
                        width: 180px;
                        -webkit-box-shadow: var(--primary-color) 3px 3px;
                        -moz-box-shadow: var(--primary-color) 3px 3px;
                        box-shadow: var(--primary-color) 3px 3px;
                        margin: auto 3px 3px auto;
                    }
                }

                @media (--xxs-viewport)
                {
                    width: 100%;
                    a
                    {
                        width: 100%;

                        img
                        {
                            width: 100%;
                        }
                    }
                }
            }

            .news-text-wrap
            {
                h3
                {
                    font-size: 20px;
                    margin-top: 0;

                    a
                    {
                        color: var(--primary-color);
                    }
                }

                p
                {
                    color: var(--black-color);
                    font-size: 15px;
                    display: inline;
                }

                .news-more
                {
                    font-size: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: relative;
                    padding-left: 5px;

                    &::before
                    {
                        @include fa('\f138');
                        color: var(--primary-color);
                        height: 16px;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &.news-single
    {
        .news-images
        {
            img
            {
                margin: 20px auto 20px;
                display: block;
                -webkit-box-shadow: var(--primary-color) 3px 3px;
                -moz-box-shadow: var(--primary-color) 3px 3px;
                box-shadow: var(--primary-color) 3px 3px;
                border: none;
            }
        }

        .news-img-wrap
        {
            float: left;
            margin: 0;
            max-width: 100%;

            a
            {
                border: none;

                img
                {
                    -webkit-box-shadow: var(--primary-color) 3px 3px;
                    -moz-box-shadow: var(--primary-color) 3px 3px;
                    box-shadow: var(--primary-color) 3px 3px;
                    margin: auto 3px 3px auto;
                }
            }

            @media (--tabletUp-viewport)
            {
                width: 100%;
            }
            @media (--sm-viewport)
            {
                margin: 0 auto;
                float: none;
            }
        }

        .teaser-text
        {
            font-weight: bold;
        }

        .news-navigator-wrap
        {
            padding: 10px 0;

            &::after,
            &::before
            {
                content: '';
                height: 3px;
                background-color: var(--background-color);
                width: 100%;
                display: inline-block;
            }

            p
            {
                margin: 0;
            }

            .prevLink
            {
                float: left;
                min-height: 1px;
                width: calc(50% - 37px);

                a
                {
                    display: inline-block;
                    width: 100%;
                    padding-left: 20px;
                    font-weight: bold;
                    text-align: right;
                    text-transform: uppercase;
                    color: var(--primary-color);

                    &::before
                    {
                        @include fa('\f060');
                        float: left;
                        padding-right: 0;
                        font-size: 20px;
                    }

                    &::after
                    {
                        content: '|';
                        font-size: 20px;
                        padding-left: 5px;
                    }
                }
            }

            .news-backlink-wrap
            {
                float: left;

                a
                {
                    font-weight: bold;
                    padding: 0 5px;
                    text-transform: uppercase;
                    color: var(--primary-color);
                    top: 3px;
                    position: relative;
                }
            }

            .nextLink
            {
                float: left;
                min-height: 1px;
                width: calc(50% - 37px);

                a
                {
                    display: inline-block;
                    width: 100%;
                    padding-right: 20px;
                    font-weight: bold;
                    text-align: left;
                    text-transform: uppercase;
                    color: var(--primary-color);

                    &::before
                    {
                        content: '|';
                        padding-right: 5px;
                        font-size: 20px;
                    }

                    &::after
                    {
                        @include fa('\f061');
                        float: right;
                        padding-right: 0;
                        font-size: 20px;
                    }
                }
            }

            @media (--xxs-viewport)
            {
                .prevLink,
                .nextLink
                {
                    a
                    {
                        font-size: 0;
                    }
                }
            }
        }
    }
}

.news-more
{
    display: inline-block;
    position: relative;
    padding-left: 5px;
    padding-right: 23px;

    &::after
    {
        content: '';
        background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/circle-chevron-right.svg', fill= var(--primary-color)) no-repeat center;
        width: 16px;
        height: 16px;
        background-size: 15px !important;
        margin-left: 5px;
        display: inline-block;
        top: 50%;
        right: 0;
        position: absolute;
        transform: translateY(-50%);
    }
}

.date-highlighted
{
    background-color: var(--secondary-color);
    font-size: 13px;
    position: absolute;
    right: 0;
    bottom: 0;
    text-transform: uppercase;
    padding: 6px 11px;
}

.news-latest-2
{
    .row
    {
        align-items: stretch;
        .article
        {
            display: flex;
            flex-direction: column;
            .news-text-wrap
            {
                flex-grow: 1;
            }
        }
    }
    > article:nth-child(2n+1)
    {
        clear: both;
    }

    .article .news-img-wrap
    {
        float: none;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: table;
    }

    .article .news-img-wrap img
    {
        float: none;
    }

    .article .news-img-wrap a
    {
        float: none;
        padding: 0;
        border: none;
    }

    .news-more
    {
        float: right;
    }

    .news-text-wrap
    {
        position: relative;
        padding-bottom: 20px;
    }

    .news-more
    {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.news .threeColumns
{
    > article:nth-child(2n+1)
    {
        clear: none;
    }

    > article:nth-child(3n+1)
    {
        clear: both;
    }
}

.news-header-only
{
    a
    {
        position: relative;
        display: inline-block;
        padding-left: 15px;
    }

    a::before
    {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/angle-right.svg', fill= var(--primary-color)) no-repeat center;
        display: inline-block;
        margin-right: 3px;
    }
}

.news-img-top-wrap
{
    float: left;
    margin: 0 30px 5px 0;
}

.news.news-single .gallery-at-bottom .news-img-top-wrap .news-img-wrap a img
{
    margin: 0;
}

.news.news-single .gallery-at-bottom .news-img-wrap a img
{
    box-shadow: none;
}

.news.news-single .news-images img
{
    box-shadow: none;
}

//Pager
.page-navigation > p
{
    display: none;
}

.page-navigation
{
    margin-bottom: 10px;

    &::after
    {
        content: ' ';
        display: table;
        clear: both;
    }
}

.f3-widget-paginator
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 15px 0 !important;
    li
    {
        background: var(--primary-color);
        color: var(--white-color);
        padding: 0;
        margin: 0 3px;

        &.current
        {
            background-color: var(--secondary-color);
            color: var(--white-color);
        }

        a,
        span
        {
            padding: 3px 10px;
            color: var(--white-color);
            text-decoration: none;
            display: block;

            &:hover,
            &:focus
            {
                background-color: var(--secondary-color);
            }
        }

        &.next a,
        &.previous a
        {
            font-size: 0;
            text-indent: 999px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: relative;
            overflow: hidden;
            text-align: center;

            &::before
            {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/chevron-left.svg', fill= var(--white-color)) no-repeat center;
                background-size: 10px !important;
                display: inline-block;
                text-indent: 0;
                width: 100%;
                height: 100%;
            }
        }

        &.next a::before
        {
            background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/chevron-right.svg', fill= var(--white-color)) no-repeat center;
        }
    }

}
